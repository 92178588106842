/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Booking from '@/models/booking/Booking'

export default {
  openDialog: function openDialog(app, bookingId: string): void {
    const updateDialog = this.updateDialog.bind(this)
    const closeDialog = this.closeDialog.bind(this)
    const handleInputDialog = this.handleInputDialog.bind(this)

    app.$axios
      .get(`/v4/site/calendars/any/bookings/${bookingId}`)
      .then(async function response(response) {
        const dialogProps = {
          booking: new Booking(response.data.data),
          value: true,
        }
        const eventProps = {
          input: function onInput(val) {
            handleInputDialog(app, val)
          },
          update: function onUpdate() {
            updateDialog()
          },
          close: function onClose() {
            closeDialog(app)
          },
        }
        const loadedComponent = await import('@/components/booking/BookingDialog.vue')
        app.dialogs.push({
          component: loadedComponent.default,
          props: dialogProps,
          events: eventProps,
        })
      })
      .catch((err) => {
        console.error('BookingDialog: error loading booking:', err)
      })
  },
  handleInputDialog: function handleInputDialog(app, value): void {
    // NOTE: Handle persistent, but for now lets just remove DOM via closeDialog
    if (!value) {
      this.closeDialog(app)
    }
  },
  closeDialog: function closeDialog(app): void {
    app.dialogs = app.dialogs.filter((dialog) => dialog.component.name !== 'BookingDialog')
    app.dialogs = []
  },
  updateDialog: function updateDialog(): void {
    // Booking - Need this for signaling back to legacy-gui that booking was updated
    // in the dialog (so f.ex. reload order)
    const iframe = document.getElementById('legacy-iframe') as HTMLIFrameElement
    if (iframe) {
      iframe.contentWindow.postMessage({ type: 'BookingDialogUpdated' }, '*')
    }
  },
  handle: function handle(app, data): void {
    this.openDialog(app, data.bookingId)
  },
}
