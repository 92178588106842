















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Person from '@/store/modules/queue/Person'
import { vxm } from '@/store'
import Status from '@/store/modules/queue/Status'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({})
export default class CustomerQueuePerson extends Vue {
  @Prop()
  private person: Person

  private process(person: Person) {
    this.$emit('process', person)
  }

  private isCalling(person: Person): boolean {
    return this.getCallTimeLeft(person) > 0
  }

  private getCallTimeLeft(person: Person): number {
    const pqs = person.queue
    if (!pqs.callAt || !vxm.queue.now || !vxm.queue.options) {
      return 0
    }
    const msSinceCall = vxm.queue.now.toMillis() - pqs.callAt.toMillis()
    const msLeftCalling = vxm.queue.options.callTimeMs - msSinceCall
    if (msLeftCalling <= 0) {
      return 0
    }
    return Math.round(msLeftCalling / 1000)
  }

  private emit(action: string, person: Person) {
    this.$emit(action, person)
  }

  private get queueNameExpedite(): string {
    return vxm.queue.queues[0].name
  }

  private get queueNameGarage(): string {
    return vxm.queue.queues[1].name
  }

  private get isGarageQueueEnabled(): boolean {
    return vxm.queue.options.enableGarageQueue
  }

  private getProcessIcon(person: Person): string {
    return person.status === Status.Expedite ? 'fa fa-arrow-alt-circle-right' : 'fa fa-arrow-alt-circle-up'
  }

  private getMoveToExpediteIcon(person: Person): string {
    if (person.queueId === 'expedite') {
      return 'fa fa-arrow-alt-circle-down'
    } else {
      return 'fa fa-arrow-alt-circle-left'
    }
  }

  private getMoveToGarageIcon(person: Person): string {
    if (person.queueId === 'expedite') {
      return 'fa fa-arrow-alt-circle-right'
    } else {
      return 'fa fa-arrow-alt-circle-down'
    }
  }

  private getCustomerLink(person: Person): string {
    let url
    if (person.customerId) {
      url = '/' + person.customerId + '/customer/show?queue_id=' + person.id
    } else {
      url = '/N0/customer/new?queue_id=' + person.id
    }
    return appendSitePrefix(url)
  }

  private getOrderLink(person: Person): string {
    if (!person.customerId) {
      return ''
    }
    let url
    if (person.orderId) {
      url = '/' + person.orderId + '/order2/main'
    } else {
      url = '/N0/order2/main'
      if (person.carId) {
        url += '?car_id=' + person.carId
      } else {
        url += '?customer_id=' + person.customerId
      }
    }
    return appendSitePrefix(url)
  }

  private getHotelLink(person: Person): string {
    if (!person.customerId) {
      return ''
    }
    let url
    if (person.tyreHotelId) {
      url = '/' + person.tyreHotelId + '/storage/item'
    } else {
      url = '/N0/storage/new?' + (person.carId ? 'car_id=' + person.carId : 'customer_id=' + person.customerId)
    }
    return appendSitePrefix(url)
  }

  private close(): void {
    console.log('close...')
    vxm.queue.close()
  }
}
