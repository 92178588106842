import LegacyFrameLoad from './strategies/LegacyFrameLoad'
import LegacyFrameShowBookingDialog from './strategies/BookingDialog'
import LegacyFrameShowTagsDialog from './strategies/TagsDialog'

// Target - ./App.vue
export default {
  LegacyFrameLoad,
  LegacyFrameShowBookingDialog,
  LegacyFrameShowTagsDialog,
}
