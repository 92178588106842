import { VuexModule, Module, action, mutation, getter } from 'vuex-class-component'
import { axios } from '@/plugins/vueaxios'
import { vxm } from '@/store'
import Vue from 'vue'

@Module({ namespacedPath: 'notifications/' })
export class NotificationsStore extends VuexModule {
  notifications= {}
  order: any[] = []
  unread = 0
  latestId = 0
  hasNotifications = false
  unreadNotificationCounter = 0
  currentIndex = 0
  removeLoadMore = false
  loadingNotification = false
  emptyNotifications = false
  initialFetch = false

  @mutation
  setNotifications(notifications): void {
    Object.values(notifications).forEach((notification) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Vue.set(this.notifications, this.currentIndex, notification)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (notification.unread === true) {
        ++this.unreadNotificationCounter
        this.hasNotifications = true
      }
      this.currentIndex++
    })
  }

  @mutation
  setUnreadCount(count: number): void {
    this.unread = count
  }

  @mutation
  setInitialFetch(value: boolean): void {
    this.initialFetch = value
  }

  @mutation
  setLatestId(id: number): void {
    this.latestId = id
  }

  @mutation
  setHasNotifications(value: boolean): void {
    this.hasNotifications = value
  }

  @mutation
  setRemoveLoadMore(value: boolean): void {
    this.removeLoadMore = value
  }

  @mutation
  setEmptyNotifications(value: boolean): void {
    this.emptyNotifications = value
  }

  @mutation
  setUnreadNotificationCounter(value: number): void {
    this.unreadNotificationCounter = value
  }

  @mutation
  setLoadingNotification(loading: boolean): void {
    this.loadingNotification = loading
  }

  @action()
  async fetchNotifications(): Promise<void> {
    try {
      this.setLoadingNotification(true)
      const { data } = await axios
        .get(this.userNotificationsUrl + '?page=1&perPage=10')
        .then((response) => response.data)
      this.setInitialFetch(true)
      if (data.length <= 9) {
        this.setRemoveLoadMore(true)
      }
      if (data.length === 0) {
        this.setEmptyNotifications(true)
      }
      this.setNotifications(data)
    } catch (error) {
      console.error('Error fetching notifications:', error)
    } finally {
      this.setLoadingNotification(false)
    }
  }

  @action()
  async fetchUnreadCounter(): Promise<void> {
    await axios.get('v4/site/notifications/get-unread-counter').then((response) => {
      const unreadcounter = response.data.data.data
      if (unreadcounter > 0) {
        this.setUnreadNotificationCounter(unreadcounter)
        this.setHasNotifications(true)
      }
    })
  }

  @action()
  async unreadNotification(data): Promise<void> {
    await axios.post(this.unreadUrl, data).catch((err) => {
      vxm.alert.onAxiosError(err, 'Error reading notification')
    })
  }

  @action()
  async resolveNotification(data): Promise<void> {
    await axios.patch('/v4/site/notifications/' + data.id, data).catch((err) => {
      vxm.alert.onAxiosError(err, 'Error resolving notification')
    })
  }

  @action()
  async readNotification(notificationId): Promise<void> {
    await axios.delete(this.unreadUrl + '/' + notificationId).catch((err) => {
      vxm.alert.onAxiosError(err, 'Error reading notification')
    })
  }

  @action
  async loadMoreNotifications(page: number): Promise<void> {
    await axios.get(this.userNotificationsUrl + '?page=' + page + '&perPage=10').then((response) => {
      if (response.data.meta.currentPage >= response.data.meta.lastPage) {
        this.setRemoveLoadMore(true)
      }
      this.setNotifications(response.data.data)
    })
  }

  get userNotificationsUrl(): string {
    return '/v4/site/notifications/get-for-user'
  }

  get unreadUrl(): string {
    return '/v4/site/notifications/unread'
  }
}

export const notifications = NotificationsStore.ExtractVuexModule(NotificationsStore)
